.main-box {
    background: white;
    width: 100vw;
    height: 100vh;
    min-height: 400px;
}

.custom-bg {
    /* background: linear-gradient(to bottom, #005BBB 50%, #FFD500 50%) !important;  */
}

.custom-text {
    /* color: #fff !important;  */
    /* text-shadow: -1px 0 #21211f, 0 1px #21211f, 1px 0 #21211f, 0 -1px #21211f !important; */
}


.main-content-container {
    text-align: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    bottom: 0;
}

.media-link-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.instagram-icon:hover {
    cursor: pointer;
    fill: rebeccapurple;
    transition: 0.5s;
}

.facebook-icon:hover {
    fill: #1877F2;
    transition: 0.5s;
    cursor: pointer;
}

.twitter-icon:hover {
    fill: rgb(26, 145, 218);
    transition: 0.5s;
    cursor: pointer;
}
.linkedin-icon:hover {
    fill: #0a66c2;
    cursor: pointer;
    transition: 0.5s;
}

.telegram-icon:hover {
    fill: #229ED9;
    cursor: pointer;
    transition: 0.5s;
}

.h1 {
    color: #21211f;
    font-size: 7.35rem;
    letter-spacing: -0.1rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 900;
}

.h2 {
    color: #21211f;
    font-size: 1.75rem;
    letter-spacing: -0.1rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 900;
}

.h3 {
    color: #43433f;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 350;
}

.menu-main-btn {
    display: inline-block;
    width: 120px;
    font-family: Impact, HelveticaNeue-CondensedBlack  , sans-serif;
    font-style: bold;
    font-weight: 300;
    background: #e22a25;
    padding: 0.75rem 1.5rem;    
    transition: all 0.5s ease;
    margin: 5px 0 0 5px;
    text-align: center;
}

.btn-lbl {
    font-size: 0.9rem;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    color: #fff;
}

.menu-main-btn:hover {
    transition: 0.5s;
    background: #21211f;
    cursor: pointer;
}

@media (max-width: 1320px) {


    .h1 {
        font-size: 5.35rem;
    }

}

@media (max-width: 800px) {
    .h1 {
        font-size: 5.35rem;
    }

    .h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 600px) {
    .h1 {
        font-size: 4.35rem;
    }

    .h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .h1 {
        font-size: 2.35rem;
    }

    .h2 {
        font-size: 1rem;
        letter-spacing: -0.05rem;
    }
}
