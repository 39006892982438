.inside-main-box {

    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100vw ;
    float: right;
}

.brief-description-block {
    box-sizing: border-box;
    position: relative;
    width: 100vw ;
    float: left;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.header-box {
    margin-top: 10px;
    display:flex;
    background-color: #F6F6F6;
}

.title-box{
    position: relative;
    left: 0;
    right: 0;
    background: rgb(38,38,47);
    background: linear-gradient(90deg, rgba(38,38,47,1) 0%, rgba(160,182,186,1) 100%);
    color: white;
    font-size: 20px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 900;
    padding: 1% 0 1% 10% ;
}

.main-resume-container {
    height: 100%;
    position: relative;
    overflow:auto;
    padding-left: 20%;
    padding-right: 20%;
}
.image-box {
    height: 100%;
}
.image {
    height: 25rem;
    width: 60%;
    margin:auto;
    min-width: 350px;

}

.skills-box h2{
    background-color: #d2d7d2;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 350;
    padding: 5px;
}

.skills-box h3{
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-style: normal;
    font-weight: 350;
    padding: 5px;

}

.btn-panel {
    justify-content: center;
    display: flex;
    width: 100%;
}

@media (max-width: 1320px) {

    .image {
        height: 400px;
        width: 100px;
        min-width: 250px;

    }

}

@media (max-width: 1000px) {
    .header-box {
        display:flex;
        flex-direction: column;
        background-color: unset;

    }

    .brief-description-block {
        box-sizing: border-box;
        width: 100vw ;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .main-resume-container {
        padding-left: unset;
        padding-right: unset;
    }

    .image {
        margin-top: 5px;
        height: 400px;
        width: 100%;
        max-width: 350px;
        min-width: unset;
    }

    .h1 {
        margin-left: auto;
        margin-right: auto;
    }
    .h2 {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 200px) {

    .image {
        margin-top: 5px;
        height: 200px;
        width: 100%;
        max-width: 350px;
        min-width: unset;
    }
}

.h1, .h2, .h3 {
    padding: 1%;
}
