
.flex-box{
    display:flex;
}
.icon {
    height: 50px;
    width: 50px;
    max-width:50px;
    min-width: 50px;
    max-height:50px;
    min-height: 50px;
}

.history-name{
    color: #21211f;
    font-size: 1rem;
    letter-spacing: 0rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif, serif;
    font-style: normal;
    font-weight: 900;
}

.history-title{
    color: #21211f;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, Sans-Serif, serif;
    font-style: normal;
    font-weight: 500;
}

.mobile-li li{
    border-bottom: 1px solid gray;
    padding: 5px 0;
}
.mobile-li li:before {
    content: "∘ ";
}

.history-box {
    margin: 1% 0 1% 1%;
}

.history-box:after {
    content: " ";
    clear:both;
    display:block;
    padding-top: 10px;
    }

    .history-box h2{
        background-color: #d2d7d2;
        font-weight: normal;
    }
    
    .history-box h3{
        font-weight: normal;
    }


.history-description {
    margin: 0 0 0 1%;
}

.web-url{
    cursor: pointer;
}

@media (max-width: 1000px) {
    .history-name{
        font-size: 0.9rem;
        letter-spacing: 0rem;
        font-weight: 900;
    }

    .history-title{
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
        font-weight: 500;
    }
}
